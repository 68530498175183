import { baseCasApi } from './casApi';
import { apiHeadersType } from '@/constants/config';
import { isEmpty } from 'lodash';
import { removeAuthTokens, saveAccessToken } from '@/utils/token';
import { appendStr } from '@/utils/dentall-components/string';
import { appInitFetching, updateCurrentUser } from '@/container/HomePage/slice/authSlice';
import { socket } from '@/utils/socketUtil';

export const authApi = baseCasApi.injectEndpoints({
  endpoints: build => ({
    fetchProfile: build.query({
      query: () => {
        return {
          url: '/users/profile',
          method: 'GET',
          headers: {
            'content-type': apiHeadersType.jsonType,
          },
        };
      },
      transformResponse: response => {
        return response.data;
      },
      transformErrorResponse: response => {
        return response.data?.error || response.error;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateCurrentUser(data));
          if (data._id) {
            socket.emit('joinRoom', { roomId: data._id });
          }
        } catch (err) {
          console.error(err);
        }
        dispatch(appInitFetching({ appInit: false }));
      },
    }),
    renewToken: build.query({
      query: () => ({
        url: `/auth/access-token:renew`,
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: response => {
        return response.data;
      },
      transformErrorResponse: response => {
        return response.data?.error || response.error;
      },
    }),
    stVerify: build.query({
      query: ({ st }) => {
        return {
          url: `/auth/access-token/${st}`,
          method: 'GET',
          headers: {
            'content-type': apiHeadersType.jsonType,
          },
        };
      },
      transformResponse: response => {
        return response.data;
      },
      transformErrorResponse: response => {
        return response.data?.error || response.error;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          saveAccessToken(data);
          const params = new URLSearchParams(arg.search);
          const queriedStr = Object.fromEntries([...params]);
          delete queriedStr.st;
          const redirectStr = isEmpty(queriedStr) ? '' : appendStr('', queriedStr);
          arg.navigate(`${window.location.pathname}${redirectStr}`);
        } catch (err) {
          await removeAuthTokens();
          console.error('Failed to verify ST: ', err);
          dispatch(updateCurrentUser(undefined));
          arg.navigate(window.location.pathname);
        }
        dispatch(appInitFetching({ appInit: false }));
      },
    }),
  }),
  overrideExisting: false,
});

export const { useStVerifyQuery, useFetchProfileQuery } = authApi;
