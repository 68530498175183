import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useRoutes, useSearchParams } from 'react-router-dom';
import { useAuth } from '@/hooks';
import { mainRoutes } from '@/routes';
import Loading from '@/components/Loading';
import { getAccessToken } from '@/utils/token';
import { SSORedirectUtil } from '@/utils/auth';
import { fixedPermissionRoute, PERMISSION_PATH } from '@/constants/permissionRoute';
import { BILL_PATH, ORDER_PATH } from '@/constants/routePath';
import useCurrentPath from '@/hooks/useCurrentPath';

export const AppRoutes = () => {
  const { currentUser, isFetching } = useAuth();
  const isLogin = !_.isEmpty(currentUser);
  const accessToken = getAccessToken();
  const { pathname = '' } = useLocation();
  const [searchParams] = useSearchParams();
  const st = searchParams.get('st') || '';
  const [childId, setChildId] = useState({
    wsId: '',
    orderId: '',
    billId: '',
  });
  const matchPermissionPath = !_.isEmpty(
    useCurrentPath({
      routes: fixedPermissionRoute({ wsId: childId.wsId, billId: childId.billId, orderId: childId.orderId }),
    }),
  );
  const main = useRoutes(mainRoutes({ currentUser, matchPermissionPath }));

  useEffect(() => {
    const splitPath = pathname.split('/').filter(s => !_.isEmpty(s));
    if (!_.isEmpty(splitPath[1]) && PERMISSION_PATH.indexOf(splitPath[1]) > -1) {
      if (splitPath[1] === ORDER_PATH && !_.isEmpty(splitPath[2])) {
        setChildId(prev => ({ ...prev, wsId: splitPath[0], orderId: splitPath[2] }));
      } else if (splitPath[1] === BILL_PATH && !_.isEmpty(splitPath[2])) {
        setChildId(prev => ({ ...prev, wsId: splitPath[0], billId: splitPath[2] }));
      } else {
        setChildId(prev => ({ ...prev, wsId: splitPath[0] }));
      }
    }
  }, [pathname]);

  if (isFetching) return <Loading />; // fetching : render null
  if (!isLogin) {
    // 尚未登入或還沒取得 user
    if (_.isEmpty(accessToken)) {
      if (_.isEmpty(st)) {
        if (matchPermissionPath) {
          // 沒登入無法進入需要權限的 page
          return SSORedirectUtil.redirectCasLoginPath(true);
        }
        // 沒有權限限制的 route, 未登入也要能進入
        return <>{main}</>;
      }
      return <Loading />; // 有 st 表示正在向 cas 請求驗證但 api 尚未返回，沒取到 res 先返回 loading
    }
    return <Loading />; // 有 accessToken 表示有登入但 api 尚未返回，沒取到 user 先返回 loading
  }

  return <>{main}</>;
};
