import { casLoginUrl } from '@/constants/config';
import { appendStr, getHref, redirectHrefTo } from '@/utils/dentall-components/string';

export class SSORedirectUtil {
  static redirectCasLoginPath(useRecentUrl = true) {
    if (useRecentUrl) {
      return redirectHrefTo(appendStr(casLoginUrl, { redirectUrl: getHref() }));
    }
    return redirectHrefTo();
  }
}
