import { createBrowserHistory } from 'history';
import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { baseApi } from './services/api';
import { baseCasApi } from './services/cas/casApi';
import { baseWsApi } from './services/ws/wsApi';
import cartPageReducer from '@/container/CartPage/slice';
import homePageReducer from './container/HomePage/slice';
import shopPageReducer from './container/ShopPage/slice';
import collectionPageReducer from '@/container/CollectionPage/slice';
import couponPageReducer from '@/container/CouponPage/slice';
import depositPageReducer from '@/container/DepositPage/slice';
import orderPageReducer from '@/container/OrderPage/slice';
import notifyPageReducer from '@/container/NotifyPage/slice';
import billPageReducer from '@/container/BillPage/slice';
import { appInitFetching, updateCurrentUser } from '@/container/HomePage/slice/authSlice';
import { authApi } from '@/services/cas/auth';
import { removeAuthTokens, saveAccessToken } from '@/utils/token';
export const history = createBrowserHistory();

export const rtkQueryErrorHandler = api => next => async action => {
  const { dispatch } = api;
  if (isRejectedWithValue(action)) {
    const payload = action.payload;
    switch (payload.code) {
      case 'ACCESS_TOKEN_IS_EXPIRED':
        try {
          dispatch(appInitFetching({ appInit: true }));
          // Call renewToken API to get a new token
          const renewAccessToken = await dispatch(authApi.endpoints.renewToken.initiate()).unwrap();
          saveAccessToken(renewAccessToken);

          // Retry the original API request with the new token
          const originalRequest = action.meta.arg;
          const endpointName = originalRequest.endpointName;
          const endpointAction = baseCasApi.endpoints[endpointName];
          await dispatch(endpointAction.initiate(originalRequest));
          dispatch(appInitFetching({ appInit: false }));
        } catch (renewError) {
          console.error('Token renewal failed', renewError);
          removeAuthTokens();
          dispatch(updateCurrentUser(undefined));
          dispatch(appInitFetching({ appInit: false }));
        }
        break;
      case 'REFRESH_TOKEN_IS_EXPIRED':
      case 'REFRESH_TOKEN_NOT_FOUND':
        removeAuthTokens();
        dispatch(updateCurrentUser(undefined));
        dispatch(appInitFetching({ appInit: false }));
        break;
      default:
        break;
    }
  }
  return next(action);
};

export default configureStore({
  reducer: {
    [baseCasApi.reducerPath]: baseCasApi.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
    [baseWsApi.reducerPath]: baseWsApi.reducer,
    homePageReducer,
    shopPageReducer,
    cartPageReducer,
    collectionPageReducer,
    couponPageReducer,
    depositPageReducer,
    orderPageReducer,
    notifyPageReducer,
    billPageReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      baseApi.middleware,
      baseCasApi.middleware,
      baseWsApi.middleware,
      rtkQueryErrorHandler,
    ),
  devTools: process.env.REACT_APP_ENV === 'dev',
});
