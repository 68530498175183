export const apiHeadersType = {
  jsonType: 'application/json',
};
export const sessionDomain = process.env.REACT_APP_SESSION_DOMAIN || '';
export const apiUrl = process.env.REACT_APP_SELF_API_URL || '';
export const casUrl = process.env.REACT_APP_CAS_URL || '';
export const casApiUrl = process.env.REACT_APP_CAS_API_URL || '';
export const wsSubsystemApiUrl = process.env.REACT_APP_WS_SUBSYSTEM_API_URL || '';
export const wsUrl = process.env.REACT_APP_WS_URL || '';
export const labUrl = process.env.REACT_APP_LAB_URL || '';
export const shopUrl = process.env.REACT_APP_PURCHASE_URL || '';
export const wwwUrl = process.env.REACT_APP_WWW_URL || '';
export const casLoginUrl = process.env.REACT_APP_CAS_LOGIN_URL || '';
export const casSwitchAuthUrl = process.env.REACT_APP_CAS_SWITCH_AUTH_URL || '';
export const casLogoutUrl = process.env.REACT_APP_CAS_LOGOUT_URL || '';
export const myClinicAccountLink = process.env.REACT_APP_CAS_MYACCOUNT_URL || '';
export const myAccountLink = process.env.REACT_APP_WWW_MYACCOUNT_URL || '';
export const helpfulLink = 'https://dentall.notion.site/e-e0671e8589a24d7da5e9c42ff3a92e72';
export const lineLink = 'https://line.me/ti/p/%40dentaltw';
export const wwwFbLink = 'https://www.facebook.com/dentaltwcom';
export const privacyPolicyLink = 'https://storage.googleapis.com/dentaltw-com-static/private_policy.pdf';
export const platformTermsOfServiceLink =
  'https://storage.googleapis.com/dentaltw-com-static/platform_terms_of_use.pdf';
export const gcsDentaltwStorageUrl = 'https://storage.googleapis.com/dentaltw-storage/images';
