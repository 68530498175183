import Cookies from 'js-cookie';
import { sessionDomain } from '@/constants/config';

export const getAccessToken = () => {
  return Cookies.get('at');
};

export const removeAccessToken = () => {
  Cookies.remove('at', { domain: sessionDomain });
};

export const saveAccessToken = (accessToken, exp) => {
  Cookies.set('at', accessToken, { expires: exp, domain: sessionDomain });
};

export const removeAuthTokens = () => {
  removeAccessToken();
  removeWorkspaceId();
};

export const getWorkspaceId = () => {
  return Cookies.get('wsId', { domain: sessionDomain });
};

export const saveWorkspaceId = (wsId, exp = 30) => {
  return Cookies.set('wsId', wsId, { expires: exp, domain: sessionDomain });
};

export function removeWorkspaceId() {
  return Cookies.remove('wsId', { domain: sessionDomain });
}
